import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');
const Components = () => import(/* webpackChunkName: "home" */'../views/Components');
const OurWork = () => import(/* webpackChunkName: "home" */'../views/OurWork');
const GetQuote = () => import(/* webpackChunkName: "home" */'../views/GetQuote');
const ContactUs = () => import(/* webpackChunkName: "home" */'../views/ContactUs');
const Webapp = () => import(/* webpackChunkName: "home" */'../views/services/Web-app');
const Webdesign = () => import(/* webpackChunkName: "home" */'../views/services/Web-design');
const Software = () => import(/* webpackChunkName: "home" */'../views/services/Software');
const Ecommerce = () => import(/* webpackChunkName: "home" */'../views/services/Ecommerce');
const Mobapp = () => import(/* webpackChunkName: "home" */'../views/services/Mob-app');
const Android = () => import(/* webpackChunkName: "home" */'../views/services/Android');
const iOSapp = () => import(/* webpackChunkName: "home" */'../views/services/iOS-app');
const Crossplatform = () => import(/* webpackChunkName: "home" */'../views/services/Cross-platform');
const Digitalmarketing = () => import(/* webpackChunkName: "home" */'../views/services/Digital-marketing');
const SEO = () => import(/* webpackChunkName: "home" */'../views/services/SEO');
const SMM = () => import(/* webpackChunkName: "home" */'../views/services/SMM');
const ORM = () => import(/* webpackChunkName: "home" */'../views/services/ORM');
const AcademicMgnt = () => import(/* webpackChunkName: "home" */'../views/services/Academic-mgnt.vue');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/components/',
            name: 'Components',
            component: Components
        },
        {
            path: '/web-app-thrissur-kerala/',
            name: 'Web-app',
            component: Webapp
        },
        {
            path: '/web-design-thrissur-kerala/',
            name: 'Web-design',
            component: Webdesign
        },
        {
            path: '/software-development-thrissur-kerala/',
            name: 'Software',
            component: Software
        },
        {
            path: '/e-commerce-thrissur-kerala/',
            name: 'Ecommerce',
            component: Ecommerce
        },
        {
            path: '/mobile-application-development-thrissur-kerala/',
            name: 'Mob-app',
            component: Mobapp
        },
        {
            path: '/android-app-development-thrissur-kerala/',
            name: 'Android',
            component: Android
        },
        {
            path: '/ios-app-development-thrissur-kerala/',
            name: 'iOS-app',
            component: iOSapp
        },
        {
            path: '/cross-platform-thrissur-kerala/',
            name: 'Cross-platform',
            component: Crossplatform
        },
        {
            path: '/digital-marketing-thrissur-kerala/',
            name: 'Digital-marketing',
            component: Digitalmarketing
        },
        {
            path: '/seo-services-thrissur-kerala/',
            name: 'SEO',
            component: SEO
        },
        {
            path: '/social-media-marketing-thrissur-kerala/',
            name: 'SMM',
            component: SMM
        },
        {
            path: '/online-reputation-management-thrissur-kerala/',
            name: 'ORM',
            component: ORM
        },
        {
            path: '/academic-management-thrissur-kerala/',
            name: 'AcademicMgnt',
            component: AcademicMgnt
        },
        {
            path: '/our-works/',
            name: 'OurWork',
            component: OurWork
        },
        {
            path: '/get-quote/',
            name: 'GetQuote',
            component: GetQuote
        },
        {
            path: '/contact-us/',
            name: 'ContactUs',
            component: ContactUs
        },
        {
            path: '/contact-us/',
            name: 'ContactUs',
            component: ContactUs
        }
    ]
};
